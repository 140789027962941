<template>
    <c-panel>
        <c-grid class="widths-50-all widths-100-md spacing-xl-all">
            <c-grid-item>
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <h1>Fine Art Services</h1>
                        <p>
                            Coho Prints provides a variety of services for photographers and artists. Below you will
                            find examples of some of these services, though other custom services and projects are
                            welcomed.
                        </p>

                        <ul class="dashed my-3">
                            <li>
                                <span>Art Digitization: </span>
                                <span class="text-light text-sm">$20 per art piece</span>
                            </li>
                            <li>
                                <span>Image Editing: </span>
                                <span class="text-light text-sm">$15 per quarter hour</span>
                            </li>
                            <li>
                                <span>Image Restoration: </span>
                                <span class="text-light text-sm">$20 per quarter hour</span>
                            </li>
                            <li>
                                <span>ICC Profile Creation: </span>
                                <span class="text-light text-sm">$60 per profile</span>
                            </li>
                            <li>
                                <span>Canvas Stretching: </span>
                                <span class="text-light text-sm">$10 per linear foot (frame perimeter)</span>
                            </li>
                        </ul>
                        <p class="text-light text-sm">
                            Note that the prices above are estimates and may be subject to change based on your specific
                            needs.
                        </p>
                    </c-grid-item>
                    <c-grid-item>
                        <h2 class="mt-5">How It Works</h2>
                        <p class="mt-2">
                            To get started, fill out the Service Request form by clicking the button below. You will
                            receive a response within 24 hours with a price quote and next steps.
                        </p>
                    </c-grid-item>
                    <c-grid-item>
                        <c-button class="large" @click="$router.push('/request')">Start a Request</c-button>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
            <c-grid-item>
                <h1>Recent Work</h1>
                <c-feed category="Services" />
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>
